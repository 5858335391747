#errorToast{
    left: 45%;
}

#login{
    
    section{
        height: 100vh;
        background-size: cover;
    }
    
    .section-1-cover{
        background-size: cover;
    }

    .full-card{
        height: 876px;
    }

    .google-login{
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
        border: 1px solid #7d815f !important;
        border-radius: 5px !important;
    }
    
    .google-login:hover{
        background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
    }
    
    
    .facebook-login{
        font-size: 15px;
        width: 100%;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
        border: 1px solid #7d815f !important;
        border-radius: 5px !important;
        i{
            margin-right: 15px;
        }
    }
    
    @media (max-width: 768px) { 
        #errorToast{
            left: 26.5%;
        }
    
        section{
            padding-top: 100px;
            padding-bottom: 100px;
            height: 100%;
        }
    }
}
