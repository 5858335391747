body{
    font-family: 'Montserrat', sans-serif;
    background-color: white;
}

#Contact{
    
    #section1{
        height: 100%;
        margin-top: 150px;
    }

    #section2{
        padding-top: 80px;
        padding-bottom: 80px;
        
        h2{
            margin-bottom: 80px;
        }
    }
}