.editor-class{
    border: 1px solid #008e95;
    border-top: 0px;
    padding: 10px;
    max-width: 100%;
    max-height: 415px;
    height: 250px !important;
    background-color: white;
}

.toolbar-class{
    margin-bottom: 0px !important;
    border: 1px solid #008e95 !important;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
}

.rdw-editor-wrapper{
    width: 100%;
}

.all-interests{
    height: 375px;
    overflow: auto;
}


ul::-webkit-scrollbar {
    background-color: #eeeeee;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
ul::-webkit-scrollbar-track {
    background-color: #eeeeee;
}

/* scrollbar itself */
ul::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
ul::-webkit-scrollbar-button {
    display:none;
}


.editor-class::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
.editor-class::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
.editor-class::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.editor-class::-webkit-scrollbar-button {
    display:none;
}

.google-places{
    [type='text']:focus {    
        outline: 2px solid transparent;
        outline-offset: 2px;
        --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgb(28, 100, 242, 0.0);
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
        border-color: #fff;
    }
}


*, ::before, ::after {
    --tw-ring-color: rgb(63 131 248 / 0.0);
}

.stepper-desc{
    white-space: pre-line;
}

.css-2b097c-container{
    width: 100% !important;
}


#range{
    .thumb{
        width: 50%;
    }
}


@media (max-width: 1445px){
    #range{
        .thumb{
            width: 67%;
        }
    } 
}


@media (max-width: 1440px){
    #range{
        .thumb{
            width: 70%;
        }
    } 
}


@media (max-width: 768px) { 

    .editor-class{
        width: 100% !important;
    }

    .rdw-editor-wrapper{
        width: 100%;
    }

    #range{
        .thumb{
            width: 75%;
        }
    }
}