body{
    font-family: 'Montserrat', sans-serif;
    background-color: white;
}


#OurEthos{
    #section1{
        height: 40vh;
        background-size: cover;
        background-position: center;
    }

    #section2{
        padding-top: 100px;
        padding-bottom: 20px;

        .ethos-1{
            width: 800px;
            .section2-img{
                background-size: cover;
            }
        }

        h2{
            font-size: 25px;
            font-weight: bold;
        }

        p{
            font-size: 18px;
        }
    }


    #section3{
        padding-top: 80px;
        padding-bottom: 100px;

        .ethos-1{
            width: 100%;
            height: 500px;
            .section2-img{
                width: 100%;
                background-size: cover;
            }
        }

        h2{
            font-size: 25px;
            font-weight: bold;
        }
    }

    #section4{
        padding-top: 50px;
        padding-bottom: 80px;

        .ethos-1{
            width: 100%;
            height: 500px;
            .section2-img{
                width: 100%;
                background-size: cover;
                background-position: center;
            }
        }

        h2{
            font-size: 25px;
            font-weight: bold;
        }

        p{
            font-size: 18px;
        }
    }

    #section4-1{
        padding-top: 50px;
        padding-bottom: 80px;

        .ethos-1{
            width: 100%;
            height: 500px;
            .section2-img{
                width: 100%;
                background-size: cover;
                background-position: center;
            }
        }

        h2{
            font-size: 25px;
            font-weight: bold;
        }

        p{
            font-size: 18px;
        }
    }

    #section5{
        padding-top: 50px;
        padding-bottom: 80px;

        h2{
            font-size: 25px;
            font-weight: bold;
        }
        
        .team-desc{
            font-size: 18px;
        }
    }
}

@media (max-width: 768px) { 

    #OurEthos{
        #section2{
            padding-top: 100px;
            padding-bottom: 20px;
    
            .ethos-1{
                width: 100%;
                .section2-img{
                    background-size: cover;
                }
            }
    
            h2{
                font-size: 25px;
                font-weight: bold;
            }
    
            p{
                font-size: 18px;
            }
        }


        #section3{
            padding-top: 80px;
            padding-bottom: 100px;

            .ethos-1{
                width: 100%;
                height: 300px;
                .section2-img{
                    width: 100%;
                    background-size: cover;
                }
            }

            h2{
                font-size: 25px;
                font-weight: bold;
            }
        }

        #section4-1{
            padding-top: 50px;
            padding-bottom: 80px;
    
            .ethos-1{
                width: 100%;
                height: 100%;
                .section2-img{
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                }
            }
    
            h2{
                font-size: 25px;
                font-weight: bold;
            }
    
            p{
                font-size: 18px;
            }
        }
    }
}