* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

.s1 {
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 25pt;
}

.s2 {
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 19pt;
}

p {
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 13.5pt;
    margin: 0pt;
}

.a{
    color: #00F;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 13.5pt;
}

.s3 {
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 13.5pt;
}

li {
    display: block;
}

#l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
}

#l1>li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)". ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 13.5pt;
}

#l1>li:first-child>*:first-child:before {
    counter-increment: c1 0;
}

li {
    display: block;
}

#l2 {
    padding-left: 0pt;
    counter-reset: d1 1;
}

#l2>li>*:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal)". ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 19pt;
}

#l2>li:first-child>*:first-child:before {
    counter-increment: d1 0;
}

#l3 {
    padding-left: 0pt;
}

#l3>li>*:first-child:before {
    content: " ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

#l4 {
    padding-left: 0pt;
}

#l4>li>*:first-child:before {
    content: " ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

#l5 {
    padding-left: 0pt;
}

#l5>li>*:first-child:before {
    content: " ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}

#l6 {
    padding-left: 0pt;
}

#l6>li>*:first-child:before {
    content: " ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}