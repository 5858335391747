#task-center{
    background-color: #eeeeee;
    min-height: 93svh;

    .profile-picture-chat{
        width: 35px;
        height: 35px;
        overflow: hidden;
    
        img{
            width: 35px;
            height: 35px;
            object-fit: cover;
            object-position: top;
        }

    }

    .influencer-card{
        height: 50px;
    }

    .contract-box{
        height: 400px;
        overflow-y: auto;

        .logo{
            width: 135px;
        }

        .contract-text{
            // height: 900px;
        }
    }
}