body{
    font-family: 'Montserrat', sans-serif;
    background-color: white;
}


#influencerHome{
    #section1{
        height: 100%;
        padding-top: 75px;
        margin-top: 75px;

        h1{
            margin-top: 0;
            margin-bottom: 75px;
            font-size: 75px;
            line-height: 1em;
            font-weight: 600;
        }

        h3{
            font-size: 25px;
        }

        .active-flex{
            flex-basis:50%
        }

        .second-text{
            display: none;
        }

        .main-sent{
            display: none;
        }

        button{
            width: 100%;
            height: 80px;
            font-size: 25px;
            border-radius: 20px;
        }

        .big-text{
            border-radius:10px;
        }

        video{
            border-radius: 20px;
            width: 80%;
            object-fit: cover;
        }

        h5{
            margin-top: 100px;
        }
    }

    #section2{
        padding-top: 100px;

        h2{
            margin-top: 0;
            margin-bottom: 48px;
            font-size: 40px;
            line-height: 1.2em;
            font-weight: 500;
        }

        .product-image{
            height: 275px;
            .p-img{
                width: 100%;
                height: 100%;
                background-size:100%;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 20px;
            }
        }

        
        .product-desc{
            h5{
                height: 60px;
            }

            .short-desc{
                height: 80px;
            }
            
            .promo-req{
                height: 40px;
            }
        }

        .card{
            border-radius: 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            a{
                text-decoration: underline;
            }
        }

        .button-div{
            margin-top: 40px;
            width: 450px;
            height: 60px;
            a{
                font-size: 20px;
                border-radius: 20px;
            }
        }
    }

    #section3{
        padding-top: 100px;
        padding-bottom: 100px;

        h2{
            margin-top: 0;
            margin-top: 48px;
            font-size: 40px;
            line-height: 1.2em;
            font-weight: 500;
        }

        .main-text{
            margin-bottom: 0;
            font-size: 16px;
            line-height: 1.4em;
            font-weight: 300;
            text-align: center;
        }

        .point-ring{
            height: 2.25rem;
            width: 2.25rem;
        }

        .point-text{
            font-size: 14px;
        }

        .benefits-img{
            width: 100%;
            background-position: center;
            background-size: cover;
            border-radius: 20px;
        }
    }

    #section4{
        padding-top: 100px;
        padding-bottom: 100px;

        h2{
            margin-top: 0;
            margin-bottom: 20px;
            font-size: 40px;
            line-height: 1.2em;
            font-weight: 500;
            text-align: center;
        }

        .influencer-review{
            height: 20rem;
        }
    }
}

@media (max-width: 768px) { 
    #influencerHome{
        #section1{
            height: 100% !important;
            padding-top: 0px;

            .active-flex{
                flex-basis:33.3%
            }
    
            h1{
                margin-top: 0 !important;
                margin-bottom: 25px !important;
                font-size: 50px !important;
                line-height: 1em !important;
                font-weight: 600 !important;
            }
    
            h3{
                font-size: 18px !important;
            }
    
            .image-1{
                border-radius: 20px;
                color: white;
                background-size: cover;
                background-position: center;
                height: 200px;
                margin-top: 1rem;
            }
            
            .image-2{
                border-radius: 20px;
                background-size: cover;
                background-position: center;
                color: white;
                height: 200px;
                margin-top: 1rem;
            }
            
            .image-3{
                border-radius: 20px;
                background-size: cover;
                background-position: center;
                color: white;
                height: 200px;
                margin-top: 1rem;
            }
    
            .second-text{
                display: block !important;
                font-size: 14px;
            }
    
            .main-sent{
                display: block !important;
                font-size: 18px;
            }

            button{
                width: 100%;
                height: 80px;
                font-size: 18px;
                border-radius: 20px;
                margin-top: 25px;
            }

            video{
                width: 100%;
            }
        }
        #section2{
            padding-top: 100px;
            padding-bottom: 100px;
    
            h2{
                margin-top: 0;
                margin-bottom: 25px;
                font-size: 40px;
                line-height: 1.2em;
                font-weight: 500;
            }
            
            .button-div{
                width: 100%;
            }
        }

        #section3{
            padding-top: 0px;
            p{
                font-size: 15px;
            }

            .main-text{
                margin-bottom: 0;
                font-size: 18px;
                line-height: 1.4em;
                font-weight: 300;
            }

            .benefits-img{
                width: 100%;
                height: 200px;
                margin-bottom: 20px;
            }
        }

        #section4{
            p{
                font-size: 14px;
            }
        }
    }
}



