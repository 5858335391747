#advice-hub{
    .advice-img{
        height: 250px;
    }

    .advice-start-image{
        height: 350px;
        background-position: center;
        background-size: cover;
    }

    .advice-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p{
            margin-top: 1rem;
        }

        p:first-child{
            width: 100%;
        }
    }
}