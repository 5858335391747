@tailwind base;
@tailwind components;
@tailwind utilities;


.app-popup .swal-text{
    text-align: center;
}

.app-popup .swal-button-container{
    display: flex;
    justify-content: center;
}


