#onboarding{
    height: 100svh;
    background-size: contain;
    background-position:center;

    .each-step{
        width: 1000px;   
    
        .step-card{
            height: 450px;
        }
    }

    .onboard-card{
        height: 100svh;

        .onboarding-steps{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            align-items: center;
            width: 100%;
            justify-content: center;
        }
    }

    @media (max-width: 768px) { 
        .each-step{
            width: 100%;

            .step-card{
                height: 100%;
                min-height: 50svh;
            }
        }

        .onboard-card{
            height: 100%;
    
            .onboarding-steps{
                flex-grow: 0;
            }
        }

    }
}