#dropdownLeft{
    position: relative !important;
    inset: 0px 0px auto auto !important;   
    margin: 0px !important;    
    transform: translate(0px) !important;
}

.pure-modal-referral{
    width: 20%;
}

.profile-picture{
    width: 75px;
    height: 75px;
    overflow: hidden;

    img{
        width: 75px;
        height: 75px;
        object-fit: cover;
        object-position: top;
    }
}

.profile-picture-chat{
    width: 50px;
    height: 50px;
    overflow: hidden;

    img{
        width: 50px;
        height: 50px;
        object-fit: cover;
        object-position: top;
    }
}

#range{
    .range-container{
        height: 5vh !important;
    }
}

.messages{
    overflow-y: hidden;

    .chat-ppl{
        height: 75vh;
    }

    .chat-all-msg{
        height: 75vh;

        .chat-msg{
            overflow-y: auto;
            overflow-x: hidden;
            height: 78%;
            scroll-behavior: smooth;
        }

        .chat-my-msg{
            width: 100%;
        }

        .chat-input{
            position: relative;
            top: 0px;
            width: 100%;
        }

        .img-chat{
            img{
                width: 150px;
            }
        }

        .bg-primary-btn{
            background-color: #222831;
            color: white;
            
            span{
                padding: 0.5rem;
                letter-spacing: 0.025em;
            }
        }
        
        
        .bg-primary-btn:hover{
            background-color: #008e95;
        }
        
    }

    .chat-ppl::-webkit-scrollbar, .chat-all-msg::-webkit-scrollbar, .chat-msg::-webkit-scrollbar{
        background-color: #eeeeee;
        width: 16px;
        border-radius: 0.5rem;
    }
    
    /* background of the scrollbar except button or resizer */
    .chat-ppl::-webkit-scrollbar-track, .chat-all-msg::-webkit-scrollbar-track, .chat-msg::-webkit-scrollbar-track{
        background-color: #eeeeee;
        border-radius: 0.5rem;
    }
    
    /* scrollbar itself */
    .chat-ppl::-webkit-scrollbar-thumb, .chat-all-msg::-webkit-scrollbar-thumb, .chat-msg::-webkit-scrollbar-thumb{
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #eee;
    }
    
    /* set button(top and bottom of the scrollbar) */
    .chat-ppl::-webkit-scrollbar-button, .chat-all-msg::-webkit-scrollbar-button, .chat-msg::-webkit-scrollbar-button{
        display:none;
    }
}

.notify{
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background-color: red;
}


.title-text{
    font-size: 1.25rem !important;
    padding-top: 2rem !important;
}

.pop-input{
    width: 86% !important;
}

#search{
    #range .thumb {
        width: 31%;
    }
}

@media (max-width: 768px) { 
    .pure-modal-referral{
        width: 100%;
    }

    .pop-input{
        width: 78% !important;
    }


    .messages{
        .chat-all-msg{
            .chat-my-msg{
                width: 290px;
            }
    
            .img-chat{
                width: 40%;
            }
        }
    }

    #search{
        #range .thumb {
            width: 88%;
        }
    }
}