body{
    font-family: 'Montserrat', sans-serif;
    background-color: white;
}

.rounded-promote{
    border-radius: 1.25rem;
}

p{
    letter-spacing: 0.6px;
}

#CaseStudy{
    #section1{
        height: 100%;
        margin-top: 150px;
    }
    
    #section2{
        padding-top: 50px;
        padding-bottom: 150px;
    
        h2{
            font-size: 35px;
            margin-bottom: 40px;
        }

    
        .case-card {
            border-radius: 1.25rem;
        }
        
        .casestudy-img{
            height: 425px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 1.25rem;
        }

        .cs2{
            background-position-y: 0px;
        }

        .cs8{
            background-position-x: -150px;
        }
        
    
        .modal-img-div{
            height: 100%;
        }
    
        .modal-img{
            height: 100%;
        }
    }

    @media (max-width: 768px) { 
        .case-study-img{
            height: 445px;
        }
    }
}


#EachCaseStudy{
    margin-bottom: 100px;
    .casestudy-start-image {
        position: relative; /* Ensure the pseudo-element is contained within this element */
        height: 500px;
        background-repeat: no-repeat;
        background-size: cover;
        // background-image: url('/static/media/image.a211be2….jpg'); /* Just the image */
    }
    
    .casestudy-start-image::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)); /* Uniform gradient overlay */
        z-index: 1;
        pointer-events: none; /* Ensure it doesn't interfere with any interactions */
        border-radius: 1.25rem;
    }

    .logo-container{
        z-index: 2;
        height: 125px;
        .case-study-logo{
            width: 125px;
        }
    }

    .cs-overview{
        margin-top: 100px;
    }

    .overview-text{
        font-size: 15px;
        line-height: 25px;
    }

    .all-results{
        margin-top: 100px;
    }

    .results{
        height: 100%;
    }

    .bg-gray{
        background: #f9f9f9;
        // filter: drop-shadow();
    }

    .call-to-action-banner{
        box-shadow: 5px 5px 5px lightgray;
    }

    @media (max-width: 768px) { 
        .casestudy-start-image{
            background-position: center !important;
        }

    }
}
