#Home{

    #section1{
        padding-top: 50px;
        padding-bottom: 50px;
        // min-height: 100vh;
        min-height: 100svh;
    
        h1{
            margin-top: 0;
            margin-bottom: 40px;
            font-size: 65px;
            line-height: 1em;
            font-weight: 700;
            text-shadow: 0px 4px 4px #fff, 0 0 0 #000, 0px 4px 4px #fff;
        }
    
        .welcome-text{
            font-size: 42px;
            font-weight: 200;
        }
    
        h2{
            font-size: 20px;
        }
    
        p{
            font-size: 18px;
        }
    
        .section-1-img{
            height: 400px;
        }
    
        .image-1{
            background-size: cover;
            background-position: center;
            border-radius: 20px;
        }
        
        .image-2{
            background-size: cover;
            background-position: center;
            border-radius: 20px;
            margin-left:1rem;
        }
    
    
        .influencer-text{
            font-size: 50px;
            font-family: 'Carter One', cursive;
            line-height: 50px;
        }
    
        .startup-text{
            font-size: 50px;
            font-family: 'Carter One', cursive;
            line-height: 50px;
        }
    
        .role-text{
            font-size: 30px;
        }
    
        // .learn-more-inf{
        //     display: none;
        // }
    
        // .learn-more-srt{
        //     display: none;
        // }
    
        .role-card{
            padding: 5rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-radius: 20px;
        }
    
        .trusted-by-text{
            font-size: 25px;   
            line-height: 0.75em;
        }
        
        .trusted-by-text-sec{
            font-size: 25px;   

            span{
                background-color: white;
                padding:1px;
                border-radius: 5px;
            }
        }

        .logo-img {
            width: 200px;
        }

        .logo-img-1{
            width: 130px;
            height: 130px;
        }

        .marquee-container {
            height: 150px;
            overflow: hidden;
            line-height: 30px;
            margin-top: 25px;
          
            .marquee {
              top: 0;
              left: 0%;
              width: 100%;
              overflow: hidden;
              position: absolute;
              white-space: nowrap;
              animation: marquee 30s linear infinite;
            }
          
            .marquee2 {
                top: 0;
                left: 100%;
                width: 100%;
                overflow: hidden;
                position: absolute;
                white-space: nowrap;
                animation: marquee2 30s linear infinite;
            }

            b {
                padding-left: 10px;
            }
        }
          
        @keyframes marquee {
            0% {
              left: 0%;
            }
            100% {
              left: -100%
            }
        }
        
        @keyframes marquee2 {
            0% {
              left: 100%;
            }
            100% {
              left: 0%
            }
        }
    }
    
    @media (max-width: 768px) { 
    
        #section1{
            height: 100%;

            h1{
                font-size: 50px;
                margin-bottom: 25px;
            }

            .welcome-text{
                font-size: 25px;
                font-weight: 200;
            }
            
            .all-roles{
                height: 400px;
            }    
        
            .role-card{
                padding: 0rem;
                padding-bottom: 2rem;
                padding-top: 2rem;
            }

            .influencer-text{
                font-size: 35px;
                font-family: 'Carter One', cursive;
                line-height: 50px;
            }
        
            .startup-text{
                font-size: 35px;
                font-family: 'Carter One', cursive;
                line-height: 50px;
            }

            .role-text{
                font-size: 20px;
            }
        
            .section-1-img{
                margin-left: 1rem;
                margin-right: 1rem;
                height: 100%;
            }
        
            .image-2{
                margin-top: 1rem;
            }

            .logo-img {
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
    
            .logo-img-1{
                width: 100%;
                height: 75px;
                padding-left: 10px;
                padding-right: 10px;
            }

            .marquee-container {
                height: 90px;
            }

            .carousel-img{
                width: 150%;
            }

            img{
                margin-top: 1rem;
            }
        }
    }
}