.job-desc{
    width: 300px;
}

.job{
    cursor: pointer;
}

.pure-modal-referral{
    width: 20%;
}

.all-job{
    height: 65svh;
    overflow: auto;
}

.job-details{
    max-height: 80vh;
    height: 65svh;
    overflow: auto;
}

.my-job{
    height: 70svh;
    overflow: auto;
}

.my-job-details{
    max-height: 80vh;
    height: 70svh;
    overflow: auto;
}


.my-job::-webkit-scrollbar, .my-job-details::-webkit-scrollbar, .all-job::-webkit-scrollbar, .job-details::-webkit-scrollbar {
    background-color: #eee;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
.my-job::-webkit-scrollbar-track, .my-job-details::-webkit-scrollbar-track, .all-job::-webkit-scrollbar-track, .job-details::-webkit-scrollbar-track {
    background-color: #eee;
}

/* scrollbar itself */
.my-job::-webkit-scrollbar-thumb, .my-job-details::-webkit-scrollbar-thumb, .all-job::-webkit-scrollbar-thumb, .job-details::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #eee;
}

/* set button(top and bottom of the scrollbar) */
.my-job::-webkit-scrollbar-button, .my-job-details::-webkit-scrollbar-button, .all-job::-webkit-scrollbar-button, .job-details::-webkit-scrollbar-button {
    display:none;
}

.job-img{
    height: 200px;
}

.img-pop-up{
    margin: 0px !important;
}

.html-img{
    padding: 0px !important;
}

.job-apply-img{
    height: 250px;
    border-radius: 0.5rem;
    background-color: black;
    // background-size: 90%;
    // background-position: center;
    // background-repeat: no-repeat;

}


[multiple]:focus, [type=date]:focus, [type=datetime-local]:focus, [type=email]:focus, [type=month]:focus, [type=number]:focus, [type=password]:focus, [type=search]:focus, [type=tel]:focus, [type=text]:focus, [type=time]:focus, [type=url]:focus, [type=week]:focus, select:focus, textarea:focus, [type='checkbox']:focus, [type='radio']:focus {
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #008e95; 
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color); 
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color); 
    border-color: #008e95; 
    box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow); 
    outline: 2px solid transparent;
    outline-offset: 2px;
}


#job-post{
    #range .thumb {
        width: 48%;
    }
    
    #range .range-container {
        height: 5vh;
    }
}

.bg-primary-btn{
    background-color: #008e95;
    color: white;

    span{
        padding: 0.5rem;
        letter-spacing: 0.025em;
    }
}


.bg-primary-btn:hover{
    background-color: #222831;
}


@media (max-width: 768px) { 

    #job-post{
        #range .thumb {
            width: 86%;
        }
        
        #range .range-container {
            height: 5vh;
        }
    }

    .job-img{
        max-width: 50%;
    }
    
    .pure-modal-referral{
        width: 100%;
    }

    .all-job{
        height: 100%;
    }

    .job-desc{
        width: 250px;
    }

    .job-details{
        max-height: 100%;
        height: 100%;
    }

    #range .thumb {
        width: 90%;
    }
}