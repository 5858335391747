nav{
    padding-top: 1rem;
    padding-bottom: 1rem;

    .reach-menu{
        position: absolute; 
        inset: 0px auto auto 0px; 
        margin: 0px; 
        transform: translate(965%, 82px);
    }

    .reach-user-dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
        
        p{
            margin-left: 10px;
            margin-right: 10px;
            color: white;
        }
    }
}