nav{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .logo{
        width: 135px;
    }
    
    .logo-text{
        color: black;
    }

    #dropdownNavbar {
        transform: translate(0px, 0px) !important;
        position: relative !important;
    }
}

@media (max-width: 768px) { 

    nav{
        padding-top: 1rem;
        padding-bottom: 1rem;
        .logo{
            width: 75px;
        }
    }
}