* {
    box-sizing: border-box;
  }

#errorToast{
    left: 45%;
}

#successToast{
    left: 45%;
}

#register{ 

    section{
        height: 100vh;
        background-size: cover;
    }
    
    .section-1-cover{
        background-size: cover;
    }

    .full-card{
        height: 876px;
    }

    .pwd-text{
        width: 440px;
    }
    
    .google-login{
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
        border: 1px solid #7d815f !important;
        border-radius: 5px !important;
    }
    
    .google-login:hover{
        background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
    }
    
    .facebook-login{
        font-size: 15px;
        width: 100%;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
        border: 1px solid #7d815f !important;
        border-radius: 5px !important;
        height: 43.5px;
        i{
            margin-right: 15px;
        }
    }
    
    label[for=receive]{
        font-size: 15px;
    }
    
    .register{
        padding: 50px;
        background-color: white;
        width: '100%';
    }
    
    .registerbtn {
        background-color: black;
        color: white;
        padding: 16px 20px;
        margin: 15px 0;
        border-radius: 10px;
        cursor: pointer;
        width: 100%;
        opacity: 0.9;
    }
    
    .registerbtn:hover {
        opacity: 1;
    }
    
    .signin{
        background-color: #f1f1f1;
        text-align: center;
    }

    .bg-primary-tooltip{
        background-color: #222831 !important;
        border-top-right-radius: 0.5rem !important;
        border-bottom-right-radius: 0.5rem !important;
        border-top-left-radius: 0rem !important;
        border-bottom-left-radius: 0rem !important;
        height: 48px;
    }

    // .password-tooltip{
    //     top: 230px !important;
    //     left: 1126px !important;
    // }

    @media (max-width: 768px) { 
        #errorToast{
            left: 26.5%;
        }

        section{
            padding-top: 50px;
            padding-bottom: 50px;
            height: 100%;

            .pwd-text{
                width: 100%;
            }
        }
    }
}
    