.reach-sidebar {
    position: fixed;
    top: 0px;
    height: 100vh;

    .reach-inner-sidebar{
        height: 100%;
    }

    .sidebar-logo{
        margin-top: 0.7rem;
        margin-bottom: 4rem;
    }

}

@media screen and (max-width: 700px) {
    // .sidebar {
    //     width: 100%;
    //     height: 200px;
    //     position: fixed;
    //     bottom:0px;
    //     top: initial;
    //     overflow: auto;
        
    //     }
      }
      
// .link{
//   color: white;
//   margin: 100px;

// }