body{
    font-family: 'Montserrat', sans-serif;
    background-color: white;
}


#startupHome{
    #section1{
        height: 100vh;

        h1{
            margin-top: 0;
            // margin-bottom: 75px;
            font-size: 50px;
            line-height: 1em;
            font-weight: bolder;
            text-shadow: 2px 2px 7.5px rgba(0, 0, 0, 0.5); /* Subtle shadow for readability */

        }

        h3{
            font-size: 25px;
        }

        .active-flex{
            flex-basis:50%
        }
    

        .second-text{
            display: none;
        }

        .main-sent{
            display: none;
        }

        .text-bg{
            width: 65%;
        }

        button{
            width: 60px;
            font-size: 18px;
            border-radius: 10px;
        }
        
        .video-container video {
            object-position: 0px 0px;

        }

        .video-container {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .video-container .text-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(255, 255, 255, 1); /* Translucent white */
            mix-blend-mode: overlay; /* Blend mode to mix with background more naturally */
        }



        .logo{
            width: 46%;
            margin-top: 50px;
            height: 27%;     
        }

        .video-text{
            margin-top: 550px;
        }

        // .video-container::before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background overlay */
        //     z-index: 1;
        // }
        
        // .text-overlay {
        //     z-index: 2; /* Ensures the text is above the background overlay */
        // }
    

        .big-text{
            border-radius:10px;
        }

        .modal-background{
            background-color: rgb(0,0,0,0.5);
        }

        h5{
            margin-top: 6.25rem;
        }

        // @media screen and (max-height: 900px) {
        //     .logo{
        //         width: 890px;
        //         margin-top: 255px;
        //         margin-left: 10px;    
        //     }

        //     .video-text{
        //         margin-top: 600px;
        //     }
        // }


        @media screen and (max-width: 1366px) {
            .modal-width{
                width: 800px;
            }
        }
    }

    #logo-banner{
        overflow: hidden;
        margin-top: 40px;
        height: 150px;

        .logo-img {
            width: 100%;
        }

        .marquee-container {
            height: 150px;
            overflow: hidden;
            width: 2500px;

            .marquee {
              top: 0;
              left: 0%;
              width: 100%;
              overflow: hidden;
              position: absolute;
              white-space: nowrap;
              animation: marquee 60s linear infinite;
            }
          
            .marquee2 {
                top: 0;
                left: 100%;
                width: 100%;
                overflow: hidden;
                position: absolute;
                white-space: nowrap;
                animation: marquee2 60s linear infinite;
            }

            b {
                padding-left: 10px;
            }


            .carousel-img{
                width: 750px;
            }
        }
          
        @keyframes marquee {
            0% {
              left: 0%;
            }
            100% {
              left: -100%
            }
        }
        
        @keyframes marquee2 {
            0% {
              left: 100%;
            }
            100% {
              left: 0%
            }
        }
    }

    #section2{
        padding-top: 75px;
        padding-bottom: 150px;

        h2{
            margin-top: 0;
            margin-bottom: 20px;
            font-size: 75px;
            line-height: 1em;
            font-weight: 500;
        }

        .product-image{
            width: 100%;
            border-radius: 20px;
        }

        .influencer-img{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .card{
            border-radius: 20px;
            height: 250px;
            background-size: cover;

            p{
                font-size: 30px;
            }
        }

        .button-div{
            margin-top: 40px;
            width: 400px;
            height: 60px;
            a{
                font-size: 20px;
                border-radius: 20px;
            }
        }
    }

    #section3{
        padding-top: 100px;
        padding-bottom: 100px;

        h2{
            margin-top: 0;
            font-size: 50px;
            line-height: 1.2em;
            font-weight: 500;
        }

        .img-container{
            width: 110px;
            height: 90px;
            
            .home-case-study {
                width: 75px;
            }
        }


        // .caseStudy-image{
        //     height: 450px;
        //     background-size: cover;
        //     background-position: center;
        // }

        .button-div{
            margin-top: 40px;
            width: 400px;
            height: 60px;
            a{
                font-size: 20px;
                border-radius: 10px;
            }
        }
        // .modal-img-div{
        //     height: 100%;
        // }

        // .carousel{
        //     background-color: white;
        //     border-radius: 0.5rem;
        // }
    }


    // #section4{
    //     padding-top: 100px;
    //     padding-bottom: 100px;

    //     h2{
    //         margin-top: 0;
    //         font-size: 40px;
    //         line-height: 1.2em;
    //         font-weight: 500;
    //         text-align: center;
    //     }

    //     .influencer-review{
    //         height: 20rem;
    //     }
    // }


    
}

@media (max-width: 768px) { 
    #startupHome{

        #section1-mobile{
            height: 70vh;

            .bg-img {
                height: 40vh;
                background-position: center;
                
                h1{
                    font-size: 30px;
                    color: rgba(255, 255, 255, 1); /* Translucent white */
                    text-shadow: 2px 2px 7.5px rgba(0, 0, 0, 0.5); /* Subtle shadow for readability */
                }
            }

            .loader {
                border: 6px solid #f3f3f3;
                border-radius: 50%;
                border-top: 6px solid #008e95;
                width: 40px;
                height: 40px;
                animation: spin 1s linear infinite;
            }
            
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        }

        #section1{
            height: 100%;
    
            h1{
                margin-top: 0;
                // margin-bottom: 75px;
                font-size: 35px;
                line-height: 1em;
                font-weight: bolder;
                text-shadow: 2px 2px 7.5px rgba(0, 0, 0, 0.5); /* Subtle shadow for readability */
    
            }
    
            h3{
                font-size: 25px;
            }
    
            .active-flex{
                flex-basis:50%
            }
        
    
            .second-text{
                display: none;
            }
    
            .main-sent{
                display: none;
            }
    
            .text-bg{
                width: 65%;
            }
    
            button{
                width: 45px;
                font-size: 12px;
                border-radius: 10px;
                height: 45px;
            }
            
            .video-container video {
                object-position: 0px 0px;
    
            }
    
            .video-container {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
    
            .video-container .text-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(255, 255, 255, 1); /* Translucent white */
                mix-blend-mode: overlay; /* Blend mode to mix with background more naturally */
            }
    
            // .video-container::before {
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            //     background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background overlay */
            //     z-index: 1;
            // }
            
            // .text-overlay {
            //     z-index: 2; /* Ensures the text is above the background overlay */
            // }
        
    
            .big-text{
                border-radius:10px;
            }
    
            .modal-background{
                background-color: rgb(0,0,0,0.5);
            }
    
            h5{
                margin-top: 6.25rem;
            }
    
            @media screen and (max-width: 1366px) {
                .modal-width{
                    width: 800px;
                }
            }
        }
    
        #logo-banner{
            overflow: hidden;
            margin-top: 10px;
            height: 150px;
    
            .logo-img {
                width: 100%;
            }
    
            .marquee-container {
                height: 150px;
                overflow: hidden;
                width: 2500px;
    
                .marquee {
                  top: 0;
                  left: 0%;
                  width: 100%;
                  overflow: hidden;
                  position: absolute;
                  white-space: nowrap;
                  animation: marquee 60s linear infinite;
                }
              
                .marquee2 {
                    top: 0;
                    left: 100%;
                    width: 100%;
                    overflow: hidden;
                    position: absolute;
                    white-space: nowrap;
                    animation: marquee2 60s linear infinite;
                }
    
                b {
                    padding-left: 10px;
                }
    
    
                .carousel-img{
                    width: 750px;
                }
            }
              
            @keyframes marquee {
                0% {
                  left: 0%;
                }
                100% {
                  left: -100%
                }
            }
            
            @keyframes marquee2 {
                0% {
                  left: 100%;
                }
                100% {
                  left: 0%
                }
            }
        }

        #section2{
            padding-top: 25px;
            padding-bottom: 100px;
    
            h2{
                margin-top: 0;
                margin-bottom: 25px;
                font-size: 40px;
                line-height: 1.2em;
                font-weight: 500;
            }

            .button-div{
                width: 100%;
            }

            .card{
                border-radius: 20px;
                height: 200px;
                background-size: cover;
    
                p{
                    font-size: 20px;
                }
            }
        }

        #section2-1{
            .case-study-img{
                height: 445px;
            }

            .button-div{
                width: 100%;
            }
        }

        #section3{
            padding-top: 100px;


            .home-case-study-container{
                height: 240px;
            }
            
            p{
                font-size: 14px;
            }

            .main-text{
                margin-bottom: 0;
                font-size: 18px;
                line-height: 1.4em;
                font-weight: 300;
                text-align: center;
            }

            .benefits-img{
                width: 100%;
                height: 200px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        #section4{
            p{
                font-size: 14px;
            }
        }
    }
}



