#News{
    #section1{
        height: 40vh;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
    }

    #section2{
        padding-top: 80px;
        padding-bottom: 80px;
        h2{
            margin-bottom: 80px;
        }

        .blog-img{
            height: 275px;
            background-size: cover;
            background-position: center;
        }
        
        .blog-text{
            height: 75px;
        }
    }
}

#EachNewsPage{
    #section1{
        padding-top: 100px;
        padding-bottom: 100px;
    }

    p{
        padding-bottom: 1rem;
    }

}
