.tab-btn{
    button[aria-selected="true"]{
        color: #008e95 !important;
        border-color: #008e95 !important;
        background-color: white;
        border-radius: 0px;
    }
}


.user-info{
    background-color: #eeeeee;
    min-height: 93svh;
    padding-bottom: 10px;
    
    .pure-modal-referral{
        width: 40%;
    }

    .user-cover{
        height: 300px;
        background-size: cover;
        background-position: center;
    }

    .user-details{
        // height: 100px;
        padding-top: 50px;
        min-height: 93svh;
        padding-bottom: 50px;

        .profile-img{
            width: 100px;
            height: 100px;
            overflow: hidden;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        } 

        .more-pics{
            width: 100%;
            height: 175px;
            background-size: cover;
            background-position: center;
        }

        .button{
            border-radius: 25px;
            width: 150px;
        }

        .edit-button{
            padding-right: 20px;
            font-size: 14px;
        }  
        
        .map{
            padding: 1rem;
            #map{
                height: 300px;
            }
        }

        .chart-container{
            display: flex;
            justify-content: center;
            height: 300px;
        }
    }
    
    .interests-card{

        .interest-img{
            width: 100%;
            height: 175px;
            background-size: cover;
        }
    }

    .social{
        .button{
            border-radius: 25px;
            width: 115px;
            height: 25px;
        }

        .social-button{
            font-size: 12px;
        } 
    }

    .pics{
        .button{
            border-radius: 25px;
            width: 85px;
            height: 25px;
        }

        .pics-button{
            font-size: 12px;
        } 
    }    
}

.img-pop-up{
    width: 100%;
}

.user-edit{
    background-color: #eeeeee;
    min-height: 93svh;
    padding-bottom: 10px;

    [type='checkbox']:checked, [type='radio']:checked, .dark [type='checkbox']:checked, .dark [type='radio']:checked {
        background-color:#222831;
    }

    .preview-image{
        width: 75px;
    }

    .each-profile-img{
        height: 350px;
        img{
            height: 100%;
        }
    }
}

.user-upload{
    min-height: 93svh;
    background-color: #eeeeee;
    padding-bottom: 10px;
}


.user-social{
    min-height: 93svh;
    background-color: #eeeeee;
    padding-bottom: 10px;
}

@media (max-width: 768px) { 
    .user-details{
        height: 100% !important;
        padding-left: 0px;
    }

    .user-details-profile{
        height: 100px;
        padding-left: 0px;
    }

    .user-info{
        min-height:100%;

        .pure-modal-referral{
            width: 100%;
        }


        .edit-button{
            padding-left: 20px;
            button{
                width: 100% !important;
            }
        }
    }
}